.bullet {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .list {
    padding-left: 1em; 
    text-indent: -.7em;
  }
  
  .list::before {
    content: "• ";
    color: rgba(29, 78, 216) /* or whatever color you prefer */
}